import { ClientHintCheck } from '#app/utils/client-hints';
import { type Theme } from '#app/utils/theme.server';
import { Links, LiveReload, Meta, Scripts, ScrollRestoration } from '@remix-run/react';
import React from 'react';

function Document({
  children,
  env = {},
  nonce,
  theme = 'light',
}: {
	children: React.ReactNode
	env?: Record<string, string>
	nonce: string
	theme?: Theme
}) {
  // const isMounted = React.useRef(false);
  // React.useEffect(() => {
  //   if(isMounted.current) {
  //     return;
  //   }
  //   isMounted.current = true;
  //   if(env.MODE === 'production') {
  //     const script = document.createElement('script');
  //     script.src = 'https://analytics.bookcovery.com/script.js';
  //     script.defer = true;
  //     script.setAttribute('data-website-id', '1fda8144-0dd3-42f7-9239-c7211187f6d0');
  //     document.body.appendChild(script);
  //   }
  // }, [env.MODE]);

  return (
    <html className={`${theme} h-full overflow-x-hidden`} lang="en">
      <head>
        <ClientHintCheck nonce={nonce} />
        <meta charSet="utf-8" />
        <meta content="width=device-width,initial-scale=1" name="viewport" />
        <Meta />
        <Links />
      </head>
      <body className="bg-background text-foreground h-full overflow-hidden">
        {children}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-51GTQMPC41"></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-51GTQMPC41', {
              });
            `,
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`,
          }}
          nonce={nonce}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
              (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
              })(window, document, "clarity", "script", "ojl3i87e3t")
            `
          }}
        />
        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
        <LiveReload nonce={nonce} />
      </body>
    </html>
  );
}

export default Document;